import moment from "moment/moment";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Linking } from "react-native";
import { Project } from "./model/Project";
import { Award } from "./model/Award";
import { DataType } from "./model/DataType";
import { EducationalQualification } from "./model/EducationalQualification";
import { PublicationPatent } from "./model/PublicationPatent";

// Customizable Area Start
export enum Tab {
  Projects = "1",
  Awards = "2",
  Patents = "3",
}
import React from "react";
import { getStorageData } from ".../../../packages/framework/src/Utilities";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  userName: string;
  isVisible: boolean;
  apiToken: string | null;
  educationQualificationList: EducationalQualification[];
  projectList: Project[];
  awardList: Award[];
  patentList: PublicationPatent[];
  loadingEQ: boolean;
  activeTab: string;
  loadingProject: boolean;
  loadingAwards: boolean;
  loadingPub: boolean;
  isModalOpen: boolean;
  modalItem: DataType<unknown> | null;
  uplodaProfile: React.RefObject<HTMLInputElement>;
  uploadImg:File |null;
  uploadCover:React.RefObject<HTMLInputElement>;
  coverImg:File |null;
  profileData:  {
    "id": string,
    "type": string,
    "attributes": {
        "business_name": string,
        "business_description": string,
        "category": number | string,
        "support_email": string,
        "address": string,
        "vat_registration_number": string,
        "national_id": string,
        "photo_image": File | null,
        "banner_image_image": File | null,
        "trading_license_image":File | null,
        "vat_tax_identifier_image":File | null,
        "authorization_certificate_image":File | null,
        "authorized_id_card_image": File | null,
        "iban_certificate_image": File | null,
        "other_document_image": File | null,
        "branches": [
            {
                "id":number | string ,
                "branch_name": string,
                "branch_code": string,
                "business_hours": string,
                "location_link": string,
                "mobile_number": string
            }
        ]
    }
}
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EducationalUserProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  labelTitle: string = "";
  getEducationCallId: string = "";
  getProjectCallId: string = "";
  getAwardsCallId: string = "";
  getPatentCallId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      userName: "",
      isVisible: false,
      apiToken: null,
      educationQualificationList: [],
      projectList: [],
      awardList: [],
      patentList: [],
      activeTab: "1",
      loadingEQ: true,
      loadingProject: true,
      loadingAwards: true,
      loadingPub: true,
      isModalOpen: false,
      modalItem: null,
      uplodaProfile: React.createRef(),
      uploadCover:React.createRef(),
      uploadImg:null,
      coverImg:null,
      profileData:  {
        "id": "",
        "type": "",
        "attributes": {
            "business_name": "",
            "business_description": " ",
            "category": 7,
            "support_email": "",
            "address": "",
            "vat_registration_number": "",
            "national_id": "",
            "photo_image": null,
            "banner_image_image": null,
            "trading_license_image":null,
            "vat_tax_identifier_image":null,
            "authorization_certificate_image":null,
            "authorized_id_card_image": null,
            "iban_certificate_image": null,
            "other_document_image": null,
            "branches": [
                {
                    "id":'' ,
                    "branch_name": "",
                    "branch_code": "",
                    "business_hours": "",
                    "location_link": "",
                    "mobile_number": ""
                }
            ]
        }
    }
    };

    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    let tokenvalue = await getStorageData('token')
    this.getEducationQualification(tokenvalue)
    this.getToken();

    if (!this.isPlatformWeb()) {
      this.props.navigation.addListener("willFocus", () => this.getToken());
    }
    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
   
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getEducationCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (resJson.errors?.length > 0) {
        this.showAlert("Error", resJson.errors[0].token);
      } else {
        this.setState({
          profileData: resJson?.data,
          loadingEQ: false
        });
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getProjectCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (resJson.errors?.length > 0) {
        this.showAlert("Error", resJson.errors[0].token);
      } else {
        this.setState({ projectList: resJson.data, loadingProject: false });
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getAwardsCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (resJson.errors?.length > 0) {
        this.showAlert("Error", resJson.errors[0].token);
      } else {
        this.setState({ awardList: resJson.data, loadingAwards: false });
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getPatentCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var resJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (resJson.errors?.length > 0) {
        this.showAlert("Error", resJson.errors[0].token);
      } else {
        this.setState({ patentList: resJson.data, loadingPub: false });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async getEducationQualification(tokenProfile:string) {
    console.log("dfghjkfghj")
    const header = {
      token: tokenProfile,
      "Content-Type": configJSON.getEducationContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getEducationCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getEducationApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getEducationApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async getProjectList() {
    const header = {
      token: this.state.apiToken,
      "Content-Type": configJSON.getProjectContextType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProjectCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProjectApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getProjectApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async getAwardList() {
    const header = {
      token: this.state.apiToken,
      "Content-Type": configJSON.getAwardsContextType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAwardsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAwardsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAwardsApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async getPatentList() {
    const header = {
      token: this.state.apiToken,
      "Content-Type": configJSON.getPatentsContextType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPatentCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPatentsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPatentsApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  hideModal = () => {
    this.setState({ isModalOpen: false, modalItem: null });
  };

  showModal = (modalItem: DataType<unknown>) => {
    this.setState({ isModalOpen: true, modalItem });
  };

  setActiveTab = (activeTab: string) => {
    this.setState({ activeTab });
  };

  openUrl = async (targetUrl?: string) => {
    if (!targetUrl) {
      return;
    }

    const supported = await Linking.canOpenURL(targetUrl);
    if (supported) {
      Linking.openURL(targetUrl);
    }
  };

  formatDate = (value: string) => {
    return moment.utc(value).format("YYYY-DD-MM");
  };


  handleFileChange4 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if(file ){
    this.setState({uploadImg:file})
    console.log(file,"FGHDFGHJ");}
  };

  
  handleFileInputClick4 = () => {
    this.state.uplodaProfile.current?.click();
  };

  handleFileCoverPhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if(file ){
    this.setState({coverImg:file})
    console.log(file,"FGHDFGHJ");}
  };

  
  handleFileCoverClick = () => {
    this.state.uploadCover.current?.click();
  };

  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
